<template>
  <div class="maincontainer">
    <joymew-header></joymew-header>
    <banner></banner>
    <div class="newhome-go-list">
      <div class="ngl-top">
        <img @click="to('/app/')" src="@/assets/image/app-go.png" alt="嗨喵悦动,嗨喵互动,嗨喵App">
        <img @click="to('https://www.hudongmiao.com/course/tc0tc2.html')" src="@/assets/image/line-go.png" alt="嗨喵悦动,嗨喵互动,嗨喵台词">
        <img @click="to('/setmeal/')" src="@/assets/image/meal-go.png" alt="嗨喵悦动,嗨喵互动,嗨喵套餐">
        <img @click="to('https://screen.hudongmiao.com/#/?liveId=7b249edb6f5b420c82b31f485080b1a7&screenType=wedding')" src="@/assets/image/function-go.png" alt="嗨喵悦动,嗨喵互动,嗨喵功能">">
      </div>
      <div class="ngl-bottom">
        <div>
          <img src="@/assets/image/hdzbf.png" alt="嗨喵悦动,嗨喵互动,活动主办方">
          <div>
            <span id="hdzbf">15468</span>
            <span>活动主办方</span>
          </div>
        </div>
        <div>
          <img src="@/assets/image/zcls.png" alt="嗨喵悦动,嗨喵互动,主持老师">
          <div>
            <span id="zcls">52896</span>
            <span>主持老师</span>
          </div>
        </div>
        <div>
          <img src="@/assets/image/hdcc.png" alt="嗨喵悦动,嗨喵互动,活动场次">
          <div>
            <span id="hdcc">865679</span>
            <span>活动场次</span>
          </div>
        </div>
        <div>
          <img src="@/assets/image/xccyrc.png" alt="嗨喵悦动,嗨喵互动,现场参与人次">
          <div>
            <span id="xccyrc">103675984</span>
            <span>现场参与人次</span>
          </div>
        </div>
      </div>
    </div>
    <div class="adaptivePlace">
      <div class="title">适用场景</div>
      <div class="adativeItems">
        <div class="adativeItem">
          <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path :stroke="place2?'#008dff':'#666'" d="M29.1373 15.235C29.1373 12.1498 27.257 10.2695 24.1719 10.2695" stroke-width="0.931019" stroke-linecap="round"/>
<path :stroke="place2?'#008dff':'#666'" d="M30.3787 28.2691C30.3787 25.184 27.8777 23.3037 24.7926 23.3037" stroke-width="1.14826" stroke-linecap="round"/>
<path :stroke="place2?'#008dff':'#666'" d="M24.7928 23.5973L27.6747 26.717L21.911 26.717L24.7928 23.5973Z" fill="white"  stroke-width="1.24136" stroke-linejoin="round"/>
<path :fill="place2?'#008dff':'#666'" fill-rule="evenodd" clip-rule="evenodd" d="M4.56799 13.5039C4.56799 18.8881 8.49452 25.1465 13.5571 25.1465C18.6198 25.1465 22.5463 18.8881 22.5463 13.5039C22.5463 10.8415 21.6294 8.63621 19.8954 7.10637C18.3009 5.69575 16.0486 4.9209 13.5571 4.9209C11.0657 4.9209 8.81342 5.69575 7.21889 7.10637C5.48485 8.63621 4.56799 10.8415 4.56799 13.5039ZM6.81529 13.8438C6.81529 18.0315 9.76018 22.8992 13.5571 22.8992C17.3541 22.8992 20.299 18.0315 20.299 13.8438C20.299 11.7731 19.6114 10.0579 18.3108 8.86801C17.1149 7.77085 15.4257 7.16819 13.5571 7.16819C11.6886 7.16819 9.99936 7.77085 8.80346 8.86801C7.50293 10.0579 6.81529 11.7731 6.81529 13.8438Z" />
<path :stroke="place2?'#008dff':'#666'" d="M18.8005 14.6578C18.8005 10.9344 16.5312 8.66504 12.8077 8.66504"  stroke-width="1.12364" stroke-linecap="round"/>
<path :stroke="place2?'#008dff':'#666'" d="M20.2989 30.3892C20.2989 26.6658 17.2804 24.3965 13.557 24.3965"  stroke-width="1.38583" stroke-linecap="round"/>
<path :stroke="place2?'#008dff':'#666'" d="M13.5572 24.7514L17.0353 28.5166L10.0791 28.5166L13.5572 24.7514Z" fill="white"  stroke-width="1.49819" stroke-linejoin="round"/>
<path :fill="place2?'#008dff':'#666'" fill-rule="evenodd" clip-rule="evenodd" d="M19.5496 21.3524C20.8413 22.8937 22.5265 23.9273 24.3993 23.9273C28.594 23.9273 31.8474 18.7417 31.8474 14.2805C31.8474 12.0746 31.0877 10.2473 29.6509 8.97977C28.3298 7.81096 26.4636 7.16895 24.3993 7.16895C22.9373 7.16895 21.5747 7.49095 20.4347 8.09404C20.8309 8.59818 21.1607 9.15252 21.4218 9.75217C22.2777 9.28159 23.3012 9.0303 24.3995 9.0303C25.9478 9.0303 27.3474 9.52965 28.3383 10.4387C29.4159 11.4246 29.9856 12.8458 29.9856 14.5615C29.9856 18.0314 27.5456 22.0646 24.3995 22.0646C22.8815 22.0646 21.5279 21.1256 20.5334 19.7639C20.2396 20.3204 19.9103 20.8532 19.5496 21.3524Z" />
</svg>

          <div :class="[place2 == true ? 'subtitle1' : 'subtitle']">婚庆相亲</div>
        </div>
        <div class="adativeItem">
          <!-- <div class="itemimg"></div> -->
          <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path :stroke="place1?'#008dff':'#666'" d="M30.7591 24.3955V30.0137H23.2682V16.1786L30.7591 18.4835V24.3955Z"  stroke-width="2.24729"/>
<path :stroke="place1?'#008dff':'#666'"  d="M6.78807 30.0136V10.9986L19.5227 7.17818V30.0136H6.78807Z"  stroke-width="2.24729" stroke-linecap="round"/>
<rect :fill="place1?'#008dff':'#666'"  x="18.3993" y="28.8896" width="5.99276" height="2.24729" />
<rect :fill="place1?'#008dff':'#666'"  x="10.1587" y="15.4053" width="5.99276" height="2.24729" />
<rect :fill="place1?'#008dff':'#666'"  x="10.1587" y="20.6484" width="5.99276" height="2.24729" />
</svg>

          <div :class="[place1 == true ? 'subtitle1' : 'subtitle']">企业年会</div>
        </div>

        <div class="adativeItem">
          <!-- <img
            src="@/assets/newhome/adaptivePlace1.png"
            alt="嗨喵悦动,嗨喵互动,酒吧户外"
            srcset=""
          /> -->
          <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle :stroke="place3?'#008dff':'#666'" cx="26.8157" cy="10.9116" r="3.37093" fill="white"  stroke-width="2.24729"/>
<rect     :fill="place3?'#008dff':'#666'" x="10.3359" y="28.8896" width="12.7346" height="2.24729" rx="1.12364" />
<rect     :fill="place3?'#008dff':'#666'" x="17.8265" y="22.1475" width="8.24005" height="2.24729" rx="1.12364" transform="rotate(90 17.8265 22.1475)" />
<path   :stroke="place3?'#008dff':'#666'" d="M7.62973 12.1433C7.21878 11.6562 7.565 10.9111 8.20225 10.9111H25.2035C25.8408 10.9111 26.187 11.6562 25.776 12.1433L17.2754 22.2181C16.9761 22.5728 16.4296 22.5728 16.1303 22.2181L7.62973 12.1433Z" fill="white"  stroke-width="2.24729"/>
<path   :stroke="place3?'#008dff':'#666'" d="M5.84155 6.41699H10.3361L15.5798 14.2825"  stroke-width="2.24729" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

          <div :class="[place3 == true ? 'subtitle1' : 'subtitle']">酒吧户外</div>
        </div>
        <div class="adativeItem">
          <!-- <img
            src="@/assets/newhome/adaptivePlace2.png"
            alt="嗨喵悦动,嗨喵互动,企业营销"
            srcset=""
          /> -->
          <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="23.8394" y="5.66797" width="5.99276" height="24.7201" rx="1.49819"  :fill="place4?'#008dff':'#666'"   />
<rect x="26.0865" y="7.91504" width="1.49819" height="20.2256" rx="0.374548" fill="white"     />
<rect x="15.5988" y="13.1592" width="5.99276" height="17.2292" rx="1.49819"  :fill="place4?'#008dff':'#666'"   />
<rect x="17.8461" y="14.6572" width="1.49819" height="14.2328" rx="0.374548" fill="white"     />
<rect x="7.35925" y="18.4023" width="5.99276" height="11.9855" rx="1.49819"  :fill="place4?'#008dff':'#666'"   />
<rect x="9.60657" y="20.6494" width="1.49819" height="7.49095" rx="0.374548" fill="white"     />
</svg>

          <div :class="[place4 == true ? 'subtitle1' : 'subtitle']">企业营销</div>
        </div>
      </div>
    </div>
    <div class="adativeItemscontainer">
      <!-- 企业年会 -->
      <div class="items" v-if="place1">
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 8.png')"
            alt="年会现场使用嗨喵大屏互动"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 17.png')"
            alt="企业年会使用酒店大屏玩互动游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 18.png')"
            alt="企业员工扫码签到祝福年会圆满成功"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 19.png')"
            alt="嗨喵大屏互动让企业年会更精彩"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 20.png')"
            alt="年会现场使用嗨喵互动摇一摇游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 21.png')"
            alt="年会使用嗨喵大屏互动抽奖功能游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 22.png')"
            alt="数钞票互动游戏让年会现场更有趣"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 23.png')"
            alt="年会来宾使用手机玩嗨喵互动游戏"
            srcset=""
          />
        </div>
      </div>
      <!-- 婚庆相亲 -->
      <div class="items" v-if="place2">
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 8.png')"
            alt="婚礼现场使用大屏互动宏包雨游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 17.png')"
            alt="结婚使用酒店大屏玩互动游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 18.png')"
            alt="现场来宾扫码签到祝福新婚快乐"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 19.png')"
            alt="嗨喵大屏互动让婚礼现场更欢乐"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 20.png')"
            alt="婚礼现场使用嗨喵互动摇一摇游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 21.png')"
            alt="婚礼使用嗨喵大屏互动抽奖游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 22.png')"
            alt="追新娘互动游戏让结婚现场更有趣"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 23.png')"
            alt="婚礼现场宾客使用手机玩嗨喵互动游戏"
            srcset=""
          />
        </div>
      </div>
      <!-- 酒吧户外 -->
      <div class="items" v-if="place3">
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 8.png')"
            alt="潮流酒吧使用嗨喵大屏互动"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 17.png')"
            alt="酒吧现场使用大屏玩互动游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 18.png')"
            alt="酒吧顾客扫码签到表达欢乐心情"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 19.png')"
            alt="嗨喵大屏互动让酒吧现场更精彩"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 20.png')"
            alt="酒吧现场使用嗨喵互动摇一摇游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 21.png')"
            alt="酒吧使用嗨喵大屏互动抽奖功能游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 22.png')"
            alt="数钞票互动游戏让酒吧现场更有趣"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 23.png')"
            alt="酒吧顾客使用手机玩嗨喵互动游戏"
            srcset=""
          />
        </div>
      </div>
      <!-- 企业营销 -->
      <div class="items" v-if="place4">
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 8.png')"
            alt="商演庆典现场使用嗨喵大屏互动"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 17.png')"
            alt="庆典峰会使用大屏玩互动游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 18.png')"
            alt="庆典来宾扫码签到祝福活动圆满成功"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 19.png')"
            alt="嗨喵大屏互动让企业营销会更精彩"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 20.png')"
            alt="产品推介会现场使用嗨喵互动摇一摇游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 21.png')"
            alt="庆典峰会使用嗨喵大屏互动抽奖功能游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 22.png')"
            alt="数钞票互动游戏让庆典现场更有趣"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 23.png')"
            alt="营销庆典现场来宾使用手机玩嗨喵互动游戏"
            srcset=""
          />
        </div>
      </div>
    </div>
    <div class="fundation">
      <div class="title">基础功能</div>
      <div class="fundationItems">
        <div class="fundationItem">
          <div class="subtitle">16类互动玩法，37项核心功能。全面覆盖活动发布、签到、活动、数据</div>
        </div>
      </div>
    </div>

    <div class="fundationItemscontainer">
      <div class="items">
        <div class="itemcontainer">
          <div class="item">
            <img v-lazy="require('../assets/gamePic/scanSign.gif')" alt="微信扫码签到" srcset="" />
          </div>
          <div class="title">微信扫码签到</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/particleLottery.gif')"
              alt="活动名单抽奖"
              srcset=""
            />
          </div>
          <div class="title">活动名单抽奖</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/shakeRedEnvelopes.gif')"
              alt="手机摇宏包雨游戏"
              srcset=""
            />
          </div>
          <div class="title">手机摇宏包雨游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/whoIsBest.gif')"
              alt="手机摇一摇游戏"
              srcset=""
            />
          </div>
          <div class="title">手机摇一摇游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/countMoney.gif')"
              alt="手机数钞票游戏"
              srcset=""
            />
          </div>
          <div class="title">手机数钞票游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/dargonFlyInSky.gif')"
              alt="龙年专属飞龙在天游戏"
              srcset=""
            />
          </div>
          <div class="title">龙年专属飞龙在天游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/dragonPlaybead.gif')"
              alt="龙年专属游龙戏珠游戏"
              srcset=""
            />
          </div>
          <div class="title">龙年专属游龙戏珠游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/signLottery.gif')"
              alt="活动微信扫码签到抽奖"
              srcset=""
            />
          </div>
          <div class="title">活动微信扫码签到抽奖</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/guessMovie.gif')"
              alt="趣味猜电影互动游戏"
              srcset=""
            />
          </div>
          <div class="title">趣味猜电影互动游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img v-lazy="require('../assets/gamePic/vote.gif')" alt="活动节目投票功能" srcset="" />
          </div>
          <div class="title">活动节目投票功能</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/giantScreenLottery.gif')"
              alt="3D巨幕抽奖游戏"
              srcset=""
            />
          </div>
          <div class="title">3D巨幕抽奖游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/chaseBride.gif')"
              alt="摇一摇追新娘游戏"
              srcset=""
            />
          </div>
          <div class="title">摇一摇追新娘游戏</div>
        </div>
      </div>
    </div>
    <div class="knowMore">
      <a class="knowMore" href="https://mp.weixin.qq.com/s/OW4MafDjOO8awQRHR0HEQg" target="_blank"
        >了解更多</a
      >
    </div>

    <div class="whyChooseTitle">为什么选择嗨喵？</div>
    <div class="optionsIntroduction">
      <div class="statible">
        <div class="cover">
          <div class="title">专业稳定</div>
          <!-- <p style="font-weight:5000">专业稳定</p> -->
          <div class="comment">
            高性能服务器，超稳超安全 专业工程师、客服人员24小时在线 领先对手不止一步
          </div>
        </div>
      </div>
      <div class="convience">
        <div class="cover">
          <div class="title">操作便捷</div>
          <div class="comment">
            操作简单，极致体验，一分钟创建活动。一台联网电脑，一部手机 轻松应对各种场合
          </div>
        </div>
      </div>
      <div class="allfree">
        <div class="cover">
          <div class="title">全部免费</div>
          <div class="comment">18款互动玩法不重样，专业功能 全部免费！免费！免费！</div>
        </div>
      </div>
    </div>
    <div class="classicalCases">经典案例</div>
    <div class="classicalContainer">
      <div class="classicalItem">
        <div class="bg">
          <img
            v-lazy="require('../assets/newhome/Rectangle 31.png')"
            alt="2020年第二届安徽主持人交流峰会使用嗨喵大屏互动"
            srcset=""
          />
        </div>
        <div class="title" style="margin-left: -15px">2020年第二届安徽主持人交流峰会</div>
      </div>
      <div class="classicalItem">
        <div class="bg">
          <img
            v-lazy="require('../assets/newhome/Rectangle 33.png')"
            alt="2021嗨喵与宁波主持团队战略签约仪式"
            srcset=""
          />
        </div>
        <div class="title">2021嗨喵宁波战略签约仪式</div>
      </div>
      <div class="classicalItem">
        <div class="bg">
          <img
            v-lazy="require('../assets/newhome/Rectangle 35.png')"
            alt="嗨喵互动参加世界华人结婚产业颁奖盛典"
            srcset=""
          />
        </div>
        <div class="title">世界华人结婚产业颁奖盛典</div>
      </div>
    </div>
    <div class="partnership">嗨喵合伙人</div>
    <div class="partnershipComment">
      <span class="main">携手、聚势、合作、共赢，嗨喵悦动感谢每一位合伙人老师的付出。</span>
      <!-- <span class="clickKnow" @click="navTo" >了解嗨喵合伙人</span> -->
      <a class="clickKnow" target="_blank" href="https://mp.weixin.qq.com/s/jDPuF_BMUU7ykQTw1X-94Q"
        >了解嗨喵合伙人</a
      >
    </div>
    <div class="partnershipitems">
      <div v-lazy class="box">
        <div class="item">
          <div class="name">小坤哥</div>
          <div class="name1"></div>
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">刘奎</div>
          <div class="name2"></div>
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">杨乐</div>
          <div class="name3"></div>
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">吴刚</div>
          <div class="name4"></div>
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">上小官</div>
          <div class="name5"></div>
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">上官</div>
          <div class="name6"></div>
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">阿四</div>
          <div class="name7"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">李照</div>
          <div class="name8"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">陈宇</div>
          <div class="name9"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">天宇</div>
          <div class="name10"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">陈建桥</div>
          <div class="name11"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">申喆</div>
          <div class="name12"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">小凡</div>
          <div class="name13"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">周锋</div>
          <div class="name14"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">东明</div>
          <div class="name15"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">刘贝</div>
          <div class="name16"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">海琳</div>
          <div class="name17"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">一欣</div>
          <div class="name18"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">萝卜哥</div>
          <div class="name19"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">凯钰</div>
          <div class="name20"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">宣岩</div>
          <div class="name21"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">天悦</div>
          <div class="name22"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">马蕊</div>
          <div class="name23"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">孟良</div>
          <div class="name24"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">秋傲</div>
          <div class="name25"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">张鑫瑞</div>
          <div class="name26"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">黄恒</div>
          <div class="name27"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">振国</div>
          <div class="name28"></div>
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">小坤哥</div>
          <div class="name1"></div>
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">刘奎</div>
          <div class="name2"></div>
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">杨乐</div>
          <div class="name3"></div>
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">吴刚</div>
          <div class="name4"></div>
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">上小官</div>
          <div class="name5"></div>
          <div class="title">高级合伙人</div>
        </div>
      </div>
    </div>
    <div class="customercorpContainer">
      <div class="title">合作客户</div>
      <div class="customercorp">
        <div class="item">
          <img
            src="../assets/corPic/WeChatImage_20210612154320.jpg"
            alt="百年老字号同庆楼与嗨喵达成战略合作"
            class="customercorp-n"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/b98abc1e5e2b67b0b0484a93cdcdffa8.jpg')"
            alt="Host主持团队使用嗨喵大屏互动"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/c41f6748cedc533b50e9361f124c3e17.jpg')"
            alt="建桥主持团队使用嗨喵主持婚礼活动"
            srcset=""
            class="customercorp-n"
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/d94a88b74ee8ba241e8ac9c250f8e2cd.jpg')"
            alt="浙江珍爱主持团队与嗨喵互动达成合作"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/dab6a21bf4a7056412c2eaf1186b029e.jpg')"
            alt="婚尚荟婚庆公司与嗨喵达成合作"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/e34c509c8f7bdda24ee25a5b59d89a16.jpg')"
            alt="领秀品牌与嗨喵达成合作"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/efa95de713f8fa8108bb534c0114a6fd.jpg')"
            alt="游羊婚嫁与嗨喵达成合作"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/WechatIMG76.jpg')"
            alt="南京瑞庭婚礼宴会中心与嗨喵达成合作"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/WechatIMG77.jpg')"
            alt="艾薇拉婚礼会馆与嗨喵达成合作"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/WechatIMG78.jpeg')"
            alt="晨丰主持人工作室与嗨喵达成合作"
            srcset=""
          />
        </div>
      </div>
    </div>
    <div class="map">
      <div class="infoContainer">
        <div class="title">联系我们</div>
        <div class="info">
          <div class="left">
            <div class="title1">嗨喵悦动——让互动更精彩</div>
            <div class="detail">
              <div class="address">地址：上海普陀区中江路118号</div>
              <div class="tel">电话：400-082-2298</div>
              <div class="e-mail">邮箱：bd@hudongmiao.com</div>
            </div>
          </div>
          <div class="right">
            <div class="rightTitle">关注我们</div>
            <div class="qrPlace">
              <img
                v-lazy="require('@/assets/image/Code2.jpg')"
                class="qrcodeImg"
                alt="嗨喵悦动,嗨喵互动,公众号"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <myFooter />

    <!-- <toolBox @openQrcodeBox="openQrcodeBox" /> -->
    <qrcodeBox @closeQrcodeBox="closeQrcodeBox" v-if="qrcodeBoxVisible" />
  </div>
</template>

<script>
import banner from "@/components/layout/banner.vue";
import joymewHeader from "@/components/layout/joymewHeader.vue";
import myFooter from "@/components/myFooter.vue";

// import toolBox from '@/components/toolBox.vue';
import qrcodeBox from "@/components/qrcodeBox.vue";
import { 
  CountUp
 } from 'countup.js'
export default {
  components: {
    banner,
    joymewHeader,
    myFooter,
    // toolBox,
    qrcodeBox,
  },
  methods: {
    to(url) {
      window.open(url,'_blank')
    },
    navTo() {},

    handleMessage(e) {
      // console.log(e);
      if (e.data === "needLogin") {
        this.openLrBox(0);
      }
    },
    closeQrcodeBox() {
      this.qrcodeBoxVisible = false;
    },
    openQrcodeBox() {
      this.qrcodeBoxVisible = true;
    },
    watchScroll() {
      window.addEventListener("scroll", this.handleScroll);
    },
    show1() {
      this.place1 = false;
      this.place2 = true;
      this.place3 = false;
      this.place4 = false;
    },
    show2() {
      this.place1 = true;
      this.place2 = false;
      this.place3 = false;
      this.place4 = false;
    },
    show3() {
      this.place1 = false;
      this.place2 = false;
      this.place3 = true;
      this.place4 = false;
    },
    show4() {
      this.place1 = false;
      this.place2 = false;
      this.place3 = false;
      this.place4 = true;
    },
    showevent(i) {
      if (i === 0) {
        this.show1();
      } else if (i === 1) {
        this.show2();
      } else if (i === 2) {
        this.show3();
      } else if (i === 3) {
        this.show4();
      }
    },
  },
  created() {
    // pcIndex = 0;
    // loopLock = false;
    // pcLen = this.praiseContentList.length;
  },
  mounted() {
    new CountUp('hdzbf',15468, {
      duration: 5
    } ).start()
    new CountUp('zcls', 52896, {
      duration: 6
    }).start()
    new CountUp('hdcc', 865679, {
      duration: 7
    }).start()
    new CountUp('xccyrc',103675984, {
      duration: 8
    }).start()
    this.watchScroll();
    // this.initStatisticsNumAni();
    // const video2 = document.getElementById('myVideo');
    const video2 = document.getElementsByTagName("video");

    for (let i = 0; i < video2.length; i += 1) {
      video2[i].controls = false;
    }
    const adaptiveTitle = document.getElementsByClassName("adativeItem");
    for (let i = 0; i < adaptiveTitle.length; i += 1) {
      console.log(i);
      adaptiveTitle[i].addEventListener("mouseover", () => {
        this.showevent(i);
      });
    }
  },
  data() {
    return {
      place2: true,
      place1: false,
      place3: false,
      place4: false,

      qrcodeBoxVisible: false,
    };
  },
};
</script>

<style lang="less" scoped>
// .maincontainer {
//     font-size: ;
// }

.newhome-go-list {
  padding: 0 var(--newhome-padding);
  background: #fff;
  .ngl-top {
    position: relative;
  top: -48px;
  z-index: 9;
    display: flex;
    justify-content: space-between;
    img {
      width: calc(25% - 22.5px);
      aspect-ratio: 352 / 170;
      margin-right: 22.5px;
      transition:  all .2s;
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
      &:active {
        transform: scale(.95);
      }
      &:last-child {
          margin-right: 0;
        }
    }
  }

  .ngl-bottom {
    display: flex;
    justify-content: space-between;
    background: #fff;
    >div {
      display: flex;
      width: calc(25% - 22.5px);

      
      img {
        width: 56.25px;
        height: 56.25px;
        margin-right: 9.9975px;
        margin-left: 21px;
      }
      div {
        display: flex;
        flex-direction: column;

        span:first-child {
          font-size: 27.9975px;
          font-weight: bold;
        }

        span:last-child {
          margin-top: auto;
          font-size: 14.002500000000001px;
        }
      }
    }
  }
}
video {
  border-radius: 7.5px;
  width: 100%;
  height: 100%;
  transition: all 0.2s linear;
}
video:hover {
  // height: 375px;
  transform: scale(1.2);
}
.wrapper {
  // height: 659.25px;
  .carouselImg {
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
}

.adaptivePlace {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 72px;
  margin-bottom: 22.5px;
  .title {
    color: rgba(51, 51, 51, 1);
    font-size: 27px;
    margin-bottom: 9.75px;
    font-weight: bold;
  }
  .adativeItems {
    width: 996px;
    display: flex;
    justify-content: space-between;
    border-bottom: 0.75px dashed rgba(0, 0, 0, 0.6);
    height: 60px;
    padding: 0 75px;
    // .adativeItem:hover {
    //     color: rgba(255,204,79,1);
    // }
    .adativeItem {
      display: flex;
      align-items: center;
      // justify-content: center;
      // width: 100%;
      cursor: pointer;

      .itemimg {
        width: 36px;
        height: 36px;
        background: url("../assets/newhome/adaptivePlace4.png") no-repeat;
        background-position: 99% 100%;
        background-size: 408.75px 901.5px;
      }
      img {
        width: 36px;
        height: 36px;
      }
      .subtitle {
        color: rgba(0, 0, 0, 0.6);
        // color: #000;
        text-align: center;
        width: 100%;
        font-size: 21px;
      }
      .subtitle1 {
        color: #008dff;
        // color: #000;
        text-align: center;
        width: 100%;
        font-size: 21px;
      }
      .subtitle:hover {
        color: rgba(255, 204, 79, 1);
      }
    }
  }
}
.adativeItemscontainer {
  padding: 0 var(--newhome-padding);
  // height: 462px;
  // background: red;
  background: url("../assets/newhome/adaptiveBg.png") no-repeat;
  background-size: 100%;
  padding-top: 63px;
  .items {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    // margin: 21.75px auto;
    // margin-left: 111.75px;
    // width: 100%;
    // padding: 45px 133.5px;
    // padding-left: 120px;
    // margin-left: 111.75px;
    margin-top: 21.75px;
    .item {
      flex-basis: calc(25% - 16.665px);
      // height: 181.5px;
      aspect-ratio: 16 / 9;
      background: rgba(196, 196, 196, 1);
      // margin: 0 16.875px 0 0;
      margin-bottom: 41.25px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.fundationItemscontainer {
  // height: 462px;
  background: url("../assets/newhome/waterCloud.png") no-repeat;
  background-size: 15%;
  background-position: 0px 150px;
  padding: 0 var(--newhome-padding);
  .items {
    margin: 21.75px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // padding: ;
    // margin-left: 111.75px;
    // padding: 45px 133.5px;
    // width: 100%;
    .itemcontainer {
      width: calc(25% - 16.665px);
      aspect-ratio: 16 / 9;
      // margin: 0 16.875px 0 0;
      // width: 20%;
      // height: 147.75px;
      // background: rgba(196,196,196,1);
      // margin: 41.25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 41.25px;
      // margin-right: 20.625px;
      // margin-left: 41.25px;
      .item {
        width: 100%;
        height: 100%;
        // height: 147.75px;
        // background: rgba(196, 196, 196, 1);
        // margin: 20.625px;
        // margin-bottom: 41.25px;
        // // margin-right: 20.625px;
        // margin-left: 41.25px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .title {
        margin-top: 15px;
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
      }
    }
  }
}
.fundation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 72px;
  .title {
    color: rgba(51, 51, 51, 1);
    font-size: 27px;
    margin-bottom: 9.75px;
    font-weight: bold;
  }
  .fundationItems {
    width: 846px;
    display: flex;
    justify-content: space-between;
    border-bottom: 0.75px dashed rgba(0, 0, 0, 0.6);
    height: 60px;
    .fundationItem {
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 100%;
      img {
        width: 36px;
        height: 36px;
      }
      .subtitle {
        color: rgba(0, 0, 0, 0.6);
        // color: #000;
        text-align: center;
        width: 100%;
        font-size: 21px;
      }
    }
  }
}
.knowMore {
  width: 150.75px;
  height: 40.5px;
  background: #008dff;
  border-radius: 6px;
  line-height: 40.5px;
  text-align: center;
  margin: 0 auto;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
}
.whyChooseTitle {
  color: rgba(51, 51, 51, 1);
  font-size: 27px;
  text-align: center;
  margin-top: 72.75px;
  font-weight: bold;
}
.optionsIntroduction {
  height: 276px;
  // background: red;
  display: flex;
  // position: relative;
  // div{
  //     width: 33.3333%;
  //     background: pink;
  // }
  margin-top: 36px;
  .statible {
    position: relative;
    width: 33.3333%;
    // background: pink;
    background: url("~@/assets/newhome/bg1.jpg") no-repeat center;
    // background-position: center 0px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    // padding-top: 69px;
    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction: column;
      .title {
        color: rgba(255, 255, 255, 1);
        font-size: 27px;
        font-weight: bold;
        margin-top: 69px;
        // position: absolute ;
        // margin: 0 auto;
      }
      .comment {
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        margin: 7.5px 103.5px;
        font-weight: 400;
        // position: absolute;
        // margin-top: 112.5px;
      }
    }
  }
  .convience {
    width: 33.3333%;
    // background: rgba(0,0,0,.4);
    background: url("~@/assets/newhome/bg2.jpg") no-repeat center;
    // background-position: center 0px;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    // padding-top: 69px;
    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction: column;
      .title {
        color: rgba(255, 255, 255, 1);
        font-size: 27px;
        font-weight: bold;
        padding-top: 69px;
        // position: absolute;
      }
      .comment {
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        margin: 7.5px 103.5px;
        font-weight: 400;
        // position: absolute;
        // margin-top: 112.5px;
      }
    }
  }
  .allfree {
    width: 33.3333%;
    // background: pink;
    background: url("~@/assets/newhome/bg3.jpg") no-repeat center;
    // background-position: center 0px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    // padding-top: 69px;
    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction: column;
      .title {
        color: rgba(255, 255, 255, 1);
        font-size: 27px;
        font-weight: bold;
        margin-top: 69px;
        // position: absolute;
      }
      .comment {
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        margin: 7.5px 103.5px;
        font-weight: 400;
        // position: absolute;
        // margin-top: 112.5px;
      }
    }
  }
}
.classicalCases {
  color: rgba(51, 51, 51, 1);
  font-size: 27px;
  text-align: center;
  margin-top: 72.75px;
  font-weight: bold;
  margin-bottom: 36px;
}
.classicalContainer {
  height: 351px;
  background-color: rgba(250, 249, 248, 1);
  display: flex;
  justify-content: center;
  padding-top: 36px;
  // padding-left: 133.5px;
  // padding-right: 133.5px;
  .classicalItem {
    margin: 0 45px;
    background-color: rgba(255, 255, 255, 1);
    width: 367.5px;
    height: 279px;
    .bg {
      width: 363.75px;
      height: 193.5px;
      // background-color: pink;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      color: rgba(51, 51, 51, 1);
      font-size: 21px;
      text-align: center;
      padding: 21px 45px;
      white-space: nowrap;

      //   text-align: center;
    }
  }
}
.partnership {
  color: rgba(51, 51, 51, 1);
  font-size: 27px;
  font-weight: bold;
  text-align: center;
  margin-top: 72px;
  margin-bottom: 24px;
}
.partnershipComment {
  text-align: center;
  border-bottom: 0.75px dashed rgba(0, 0, 0, 0.6);
  padding-bottom: 12px;
  width: 65%;
  margin: 0 auto;
  .main {
    color: rgba(0, 0, 0, 0.6);
    font-size: 21px;
  }
  .clickKnow {
    color: rgba(255, 204, 79, 1);
    font-size: 21px;
    border-bottom: 0.75px solid;
  }
}
.partnershipitems {
  display: flex;
  margin: 0 var(--newhome-padding);
  margin-top: 23.25px;
  // width: 1185px;
  overflow: hidden;
  // flex-wrap: wrap;
  .box:hover {
    animation-play-state: paused;
    -webkit-animation-play-state: paused;
  }
  .box {
    animation: rolling 50s linear infinite;
    // position: absolute;
    display: flex;
    .item:nth-child(2n) .name {
      background: #008dff;
      color: rgba(255, 255, 255, 1);
    }
    .item {
      width: 222.75px;
      height: 297px;
      //   background-color: pink;
      margin-right: 15px;
      display: flex;
      text-align: center;
      flex-direction: column;
      margin-bottom: 75px;
      // 8316
      .name {
        height: 49.5px;
        line-height: 49.5px;
        color: rgba(51, 51, 51, 1);
        background-color: rgba(228, 232, 233, 1);
        font-weight: bold;
        font-size: 21px;
      }
      .name1 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/25071622011987_.pic.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name2 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/WechatIMG1779.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name3 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/yl.jpg) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name4 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24991622011944_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name5 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/shangxiaoguan.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name6 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/shangguan.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name7 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/asi.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name8 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24901622011938_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name9 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24921622011940_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name10 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24911622011939_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name11 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/WechatIMG2500.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name12 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24971622011943_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name13 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24931622011941_.pic.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name14 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/WechatIMG2529.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name15 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/dongming.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name16 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24961622011942_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name17 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/WechatIMG2510.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name18 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/yixinNew.jpg) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name19 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24981622011943_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name20 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/kaiyu.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name21 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/25031622011945_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name22 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/25051622011946_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name23 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/WechatIMG1787.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name24 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/25041622011946_.pic.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name25 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/qiuao.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name26 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/WechatIMG2920.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name27 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/25011622011945_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name28 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/zhenguo.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      img {
        display: block;
        // flex: 1;
        // width: 100%;
        height: 277.5px;
        // height: 100%;
      }
      .title {
        height: 49.5px;
        line-height: 49.5px;
        color: rgba(51, 51, 51, 1);
        background-color: rgba(228, 232, 233, 1);
        font-size: 21px;
      }
    }
  }
  @keyframes rolling {
    // from {
    //     transform: translateX(0);
    // }

    // to {
    //     transform: translateX(-50%);
    // }8856 7291
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(-6642px, 0, 0);
      transform: translate3d(-6642px, 0, 0);
    }
  }
}
.customercorpContainer {
  height: 695.25px;
  background-color: rgba(28, 35, 57, 1);
  .title {
    color: rgba(255, 255, 255, 1);
    text-align: center;
    font-size: 27px;
    font-weight: bold;
    margin-top: 72px;
    padding-top: 72px;
    padding-bottom: 36px;
  }
  .customercorp {
    padding: 0 var(--newhome-padding);
    // margin: 0 127.5px;
    display: flex;
    flex-wrap: wrap;

    // display: grid;
    // grid-template-columns: repeat(5, min-content);
    // justify-content: center;
    // column-gap: 15px;
    // row-gap: 15px;
    justify-content: space-between;
    .item {
      margin-bottom: 15px;
      width: calc(20% - 15px);
      // text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin: 0 16.875px;
      height: 222.75px;
      background-color: #ffffff;
      
      // .customercorp2 {
      //   width: 100%;
      //   height: 100%;
      //   background: url(../assets/corPic/c41f6748cedc533b50e9361f124c3e17.jpg) no-repeat;
      //   background-size: 140%;
      //   background-position: center;
      // }
      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.map {
  height: 568.5px;
  background: url("~@/assets/newhome/map.jpg") no-repeat;
  background-size: 100%;
  padding: 132px 319.5px;
  display: flex;
  justify-content: center;
  .infoContainer {
    height: 304.5px;
    width: 801px;
    background: rgba(255, 255, 255, 1);
    // margin: 132px 319.5px;
    .title {
      color: rgba(51, 51, 51, 1);
      font-size: 27px;
      font-weight: bold;
      text-align: center;
      padding: 36px 0;
    }
    .info {
      display: flex;
      // width: 100%;
      justify-content: center;
      .left {
        // width: 50%;
        border-right: 0.75px solid rgba(0, 0, 0, 0.15);
        padding: 0 76.5px;
        .title1 {
          font-weight: bold;
          font-size: 21px;
          color: rgba(51, 51, 51, 1);
        }
        .detail {
          color: rgba(51, 51, 51, 1);
          font-size: 15px;
        }
      }
      .right {
        width: 49%;
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 21px;
        font-weight: bold;
        .qrPlace {
          width: 75px;
          height: 75px;
          // background: pink;
          margin-top: 11.25px;
        }
      }
    }
  }
}
.moreDetail {
  padding: 24px 150px;
  height: 225px;
  display: flex;
  justify-content: space-between;
  .item {
    .title {
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 3px;
    }
    .detail {
      color: rgba(51, 51, 51, 1);
      font-size: 15px;
      div {
        margin: 6px 0;
      }
    }
  }
}
.bottomLogo {
  width: 344.25px;
  height: 169.5px;
  margin: 18.75px auto;
}
.bottom {
  height: 129px;
  background: rgba(250, 249, 248, 1);
  text-align: center;
  .visionRight {
    color: rgba(255, 204, 79, 1);
    font-size: 15px;
    font-weight: bold;
    padding: 18px 0;
  }
  .huICP {
    display: flex;
    justify-content: center;
    .left {
      margin-right: 36px;
    }
  }
}
.tips {
  width: 75px;
  height: 225px;
  // background: #333;
  box-shadow: 0px 1.5px 6px 0px rgba(0, 0, 0, 0.2);
  // border: 0.75px solid #000;
  background: #fff;
  position: fixed;
  top: 556.5px;
  right: 42px;
  z-index: 99999;
  div {
    width: 75px;
    height: 75px;
  }
  div {
    img {
      width: 22.5px;
      height: 22.5px;
    }
    .detail {
      // display: inline-block;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style lang="less">
.el-menu--popup {
  // width: 112.5px;
  min-width: unset;
  border-radius: 6px;
  background-color: #ffffff;
  justify-content: flex-start;
  .sItem {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: #fc9632;
    }
    .versionIcon {
      margin-right: 3px;
      margin-top: -1.5px;
    }
  }
}
</style>
